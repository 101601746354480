import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO
      title="User Agreement"
      description="You can visit our page to read the Flatart Agenct user agreement. Please read this agreement carefully to ensure that you understand each condition."
    />
    <div className="content-page">
      <div
        className="content container"
      >
        <h1>Confidentiality Agreement</h1>

<h2>Flatart Agency Confidentiality Agreement</h2>

<p>This privacy agreement has been created for people who are concerned about how personal Identifier Information for flatart.agency is used online. It is the information used to define, access or position a single individual or an individual in the environment, either individually or together with different information, as defined in information security conditions. Please read this privacy agreement carefully to understand exactly how we collect, use, protect, or what we do with your Personal Identifying Information.
</p>
<h3>What personal information do we collect from visitors to our Flatart Agency site or our mobile app?
</h3>
<p>In order to improve your experience, you may be asked for your e-mail address or different information when you register on our site or when you sign up for an e-newsletter via our flatart.agency address.

</p>

<h3>When does Flatart Agency collect information from the user?
</h3>

<p>We collect your information when you subscribe to a newsletter or enter information on our site.</p>

<h3>How do we use your information on flatart.agency?
</h3>

<p>When you register at flatart.agency, make a purchase, sign up for our newsletter, answer a survey or marketing communication, navigate the site or use some other features on the site, we may use the data we collect from you as follows: o About you order or other products and services about to throw periodic e-mails about.


</p>
<h3>How do we protect flatart.agency address?

</h3>
<p>We do not use vulnerability scanning and / or scanning to PCI standards We only provide articles and information. We definitely do not want credit card numbers on flatart.agency. We regularly use malware scanning. Your personal information is protected by secured networks and can be accessed by a limited number of people who have special access rights to such networks and are responsible for keeping this information confidential. Also, all sensitive / credit information you provide is encrypted with Secure Socket Layer (SSL) technology. When a user enters, sends or accesses his or her information, we apply various security measures to protect the user's personal information. All transactions are processed through the gateway provider and are not stored or processed on our servers.

</p>

<h3>Does Flatart Agency use Cookies?
</h3>

<p>Yes, we use cookies. Cookies are small files transferred by a website or its service provider to your hard drive via your internet browser (if you have given your consent). Cookies allow you to visit flatart.agency or the service provider to remember your web browser and capture and remember some information.


</p>
<p><b>We use cookies for the following conditions:</b></p>

<p>We compile the data we collect from site traffic and site interactions for a better site experience and improved tools in the future. In addition, we can use reliable third party services for our own good. You can choose to have your computer alert you whenever cookies are sent or to turn cookies off completely. You can do this by entering your internet browser settings. Since each browser is a little different, you can use your browser's Help Menu to find the right way to change these settings. If you turn off cookies completely, some features will be disabled on our Flatart Agency website. Disabling this feature will certainly not affect your Flatart Agency visit experience.
</p>

<h2>Use of Flatart Agency Third Party Applications
</h2>

<p>We never sell, trade or send your information used to identify the person to third parties. If the website hosting service partners and other parties who assist us in the processing of our site, in the transmission of our business or to our users, also agree to keep this information confidential, the above terms do not cover them. When its submission is lawful, information may be provided to enforce our site policy or to ensure the rights, property or security of us and others. Non-personally identifying visitor information, however, may be provided to other parties for Flatart Agency marketing, advertising and other uses.

</p>


<h3>Flatart Agency Third Party links
</h3>
<p>
At times, we may include and offer third party products or services on our website at our discretion. These third party sites have separate and independent privacy agreements. For this reason, we have no obligations and responsibilities regarding the content and activities on these affiliated sites. Nevertheless, we are trying to protect the integrity of our site and we look forward to receiving feedback on these sites.
</p>

<p>Google's advertising requirements can be summarized by Google's Advertising Principles. These principles are gathered in the link below to provide a positive experience.

</p>
<p><a href="https://support.google.com/adwordspolicy/answer/1316548?hl=tr">https://support.google.com/adwordspolicy/answer/1316548?hl=tr</a> Flatart Agency uses Google AdSense periodically. As a third party provider, Google uses cookies to serve ads on our site. By using DART cookies, Google allows our users to serve ads based on previous visits to our site and visits to other sites on the internet. Users can opt out of DART cookies by visiting the Google Advertising and Content Network privacy agreement. We apply the following: Remarketing with Google AdSense Google Display Network Reporting DoubleClick Platform Integration Demographics and Interest Reporting Along with third party providers such as Google, first party cookies (Google Analytics cookies) and third party cookies (DoubleClick cookies) or other third party identifiers,</p>

<h3>Disable Ads
</h3>

<p>Users can make changes to how Google serves ads using the settings on the Google Ads Settings page. Alternatively, they can be disabled by visiting the Network Advertising Initiative Opt Out page or by using the Google Analytics Disabled browser add-on. In case of any change in our confidentiality agreement, you will be informed by e-mail. You can change your personal information by e-mailing us.

</p>


<h3>COPPA (Children's Online Privacy Protection Act)
</h3>
<p>COPPA blames its parents on the collection of personal information from children under the age of 13. The Federal Trade Commission and the US consumer protection agency enforce the COPPA law, which clearly states what website and online service operators need to do to protect children's online privacy and online safety. We do not specifically market to children under the age of thirteen.

</p>
<h3>Fair Information Practices
</h3>

<p>Fair Information Practices Principles have played an important role in the development of data protection laws all over the world. Understanding the Fair Information Practice Principles and how they can be applied is critical to comply with various laws that protect personal information. In the event of a data breach, we will notify you within 7 business days via e-mail to comply with Fair Information Practices. We are also in agreement with the Individual Redress Principle, which requires individuals the right to pursue and enforce legal action against data collectors and processors who have failed to comply with the law. This principle requires individuals not only to have legal rights to those who use data, but also to individuals to apply to court or government agencies to prosecute non-lawful data processors.

</p>

<h2>CAN SPAM Law
</h2>
<p>
The CAN-SPAM Law is a law that sets commercial e-mail rules, determines the requirements for commercial messages, gives buyers the right to stop e-mails from being thrown, and applies criminal sanctions for any violations against these rules. We receive your e-mail address for sending you information, responding to research, and / or other requests or questions. To comply with CANSPAM, we apply the following:

</p>
<ul>
	<li>We do not use false or misleading matters or e-mail addresses.</li>
	<li>We clearly define the message as advertising. o We include the physical address of our business or site headquarters.
</li>
	<li>If any of them are used, we monitor the compatibility of third party e-mail marketing services. o We quickly evaluate requests to opt out / unsubscribe.
</li>
	<li>We provide the option to unsubscribe with the link we place at the end of each mail.
</li>
	<li>If you want to unsubscribe for future mails, you can email us.
</li>
	<li> Follow the instructions at the end of the mails. We take you out of our entire network.
</li>

</ul>


<p><b>Contact us

</b></p>

<p>If you have any questions about this privacy agreement, you can contact us at the contact information below.</p>

<p>Flatart Agency</p>
<p><a href="/">flatart.agency</a></p>


<p><a href="tel:+908509880200">+90 (850) 988 02 00</a></p>
      </div>
    </div>
  </Layout>
)

export default About
